<template>
  <section
    class="hero is-medium is-bold"
    style="background-size: cover"
    :style="{ backgroundImage: 'url(' + require('../assets/sprouts.jpg') + ')' }"
  >
    <div class="hero-body" style="background-color:rgba(255,255,255,.8">
      <div class="container has-text-centered">
        <h1 class="title">Help your relationships thrive</h1>
        <h2 class="subtitle">
          Relationship Garden is a second brain for helping you remember details
          about the people you care about and grow aquantences into meaningful
          connections.
        </h2>
      </div>
    </div>
  </section>
  <div class="box cta">
    <p class="has-text-centered">
      <span class="tag is-primary">Beta!</span> Relationship Garden is under
      active development and is currently free to use!
    </p>
  </div>
  <section class="container">
    <div class="columns features">
      <div class="column is-4">
        <div class="card is-shady">
          <div class="card-image has-text-centered">
            <i class="fa fa-brain"></i>
          </div>
          <div class="card-content">
            <div class="content">
              <h4>Second Brain</h4>
              <p>
                Doesn't it feel nice when someone remembers your birthday? Or
                your dog's birthday? Doesn't also seem impossible to "just
                remember" all that info for others? Keep track of
                what is important with rich notes and metadata about your
                contacts.
              </p>
              <!-- <p><a href="#">Learn more</a></p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="card is-shady">
          <div class="card-image has-text-centered">
            <i class="fa fa-seedling"></i>
          </div>
          <div class="card-content">
            <div class="content">
              <h4>Relationships growth</h4>
                  <p>
               Use smart suggestions around when and how to engage with your friends to make sure that you aren't letting a relationship wilt. Integrations with your existing calendars, etc. make sure it doesn't become a chore.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="card is-shady">
          <div class="card-image has-text-centered">
            <i class="fa fa-tools"></i>
          </div>
          <div class="card-content">
            <div class="content">
              <h4>Active Development</h4>
              <p>
                Relationship Garden camee out of our team's own need to help strengthen and maintain our connections, and we aren't stopping anytime soon. Submit your feedback and help guide the future of our development.
              </p>
              <!-- <p><a href="#">Learn more</a></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="intro column is-8 is-offset-2">
      <h2 class="title">Perfect for developers or designers!</h2>
      <br />
      <p class="subtitle">
        Vel fringilla est ullamcorper eget nulla facilisi. Nulla facilisi nullam
        vehicula ipsum a. Neque egestas congue quisque egestas diam in arcu
        cursus.
      </p>
    </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
require('../assets/sprouts.jpg')
export default {
  name: 'Home',
  components: {}
}
</script>
