import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faExclamationTriangle,
  faUpload,
  faTimes,
  faStickyNote,
  faUserClock,
  faCheckCircle,
  faBirthdayCake,
  faEdit,
  faTrash,
  faSave,
  faUserPlus,
  faPhone,
  faCommentDots,
  faSync,
  faUser,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faCheck,
  faBrain,
  faSeedling,
  faHeart,
  faTools,
  faClock,
  faAngleDown,
  faAngleUp,
  faSignInAlt

} from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedin, faFacebookSquare
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faEnvelope,
  faExclamationTriangle,
  faUpload,
  faTimes,
  faStickyNote,
  faUserClock,
  faCheckCircle,
  faBirthdayCake,
  faEdit,
  faTrash,
  faSave,
  faUserPlus,
  faPhone,
  faCommentDots,
  faSync,
  faUser,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faCheck,
  faBrain,
  faSeedling,
  faHeart,
  faTools,
  faLinkedin,
  faClock,
  faFacebookSquare,
  faAngleDown,
  faAngleUp,
  faSignInAlt
)

dom.watch()
require('@/assets/main.scss')
createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
