<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" :to="homeLink">
        <strong>🌱  </strong>
        <strong class="is-hidden-mobile">
         &nbsp; Relationship Garden
        </strong>
      </router-link>

      <!-- <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a> -->
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <!-- <div class="navbar-start">
        <router-link class="navbar-item" to="/tend">Tend</router-link>
      </div> -->

      <div class="navbar-end">
        <div class="navbar-item"  v-if="!user">
          <div class="buttons">
            <router-link class="button is-primary" to="/signup"
              ><strong>Sign up</strong></router-link
            >

            <router-link class="button is-light"  to="/login"
              >Login</router-link
            >
          </div>
        </div>
        <div v-if="user" class="navbar-item">
          <Search :data="[]" />
        </div>
        <div
          class="navbar-item has-dropdown"
          @click="personalMenuDisplayed = !personalMenuDisplayed"
          :class="{ 'is-active': personalMenuDisplayed }"
          v-if="user"
        >
          <a class="navbar-link"> {{ user.displayName }} </a>

          <div class="navbar-dropdown">
            <router-link to="/settings" class="navbar-item">
              Settings
            </router-link>
            <router-link to="/import" class="navbar-item"
              >Bulk Upload
            </router-link>
            <a class="navbar-item">
              <a class="button is-warning is-small" @click="logout">Logout</a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <router-view class="section" />
   <footer v-if="$route.meta.footer" class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-half ">
          <!-- <h2><strong>Category</strong></h2> -->
          <ul>
            <li><router-link to="/privacy">Privacy Policy</router-link></li>

          </ul>
        </div>
        <!-- <div class="column is-3">
           <h2><strong>Category</strong></h2>
          <ul>
            <li><a href="#">Made with intentian</a></li>

          </ul>
        </div> -->
        <div class="column is-half">
          <!-- <h2><strong>Category</strong></h2> -->
          <ul>
            <li><router-link to="/terms">Terms of Service</router-link></li>

          </ul>
        </div>
      </div>
      <div class="content has-text-centered">
        <p>
          <span
            class="icon"

          >
            <i class="fa fa-heart"></i>
          </span>
        </p>

      </div>
    </div>
  </footer>
</template>
<script>
import { ref, watchEffect } from 'vue'
import Search from './components/Search.vue'

import { user, logout } from './components/firebase.js'
export default {
  components: {
    Search

  },
  setup (props) {
    const personalMenuDisplayed = ref(false)
    const homeLink = ref('/')
    watchEffect(() => {
      if (user) {
        homeLink.value = '/tend'
      }
    })
    return {
      user,
      logout,
      personalMenuDisplayed,
      homeLink
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
