import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { ref } from 'vue'

const firebaseConfig = {
  apiKey: 'AIzaSyAqVfjBl0WCcJ7NPzVxr-1C3Jl135H6Yq4',
  authDomain: 'relationship-garden.firebaseapp.com',
  projectId: 'relationship-garden',
  storageBucket: 'relationship-garden.appspot.com',
  messagingSenderId: '34846224367',
  appId: '1:34846224367:web:bea9168d7f242d7536501a'
}

export const user = ref(null)
export const initialised = ref(false)
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion
export const fieldDelete = firebase.firestore.FieldValue.delete
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
export const db = firebase.firestore()

auth.onAuthStateChanged(u => {
  user.value = u
  initialised.value = true
})

export async function logout () {
  await auth.signOut()
}

export async function google () {
  const mqStandAlone = '(display-mode: standalone)'
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    await auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider())
  } else {
    await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }
}
export function useSignup () {
  const email = ref('')
  const password = ref('')

  async function signup () {
    if (email.value === '' || password.value === '') return

    const creds = await auth.createUserWithEmailAndPassword(
      email.value,
      password.value
    )

    if (!creds.user) throw Error('Signup failed')

    user.value = creds.user
  }

  return {
    email,
    password,
    signup
  }
}
export async function useLogin () {
  const email = ref('')
  const password = ref('')

  async function login () {
    const resp = await auth.signInWithEmailAndPassword(
      email.value,
      password.value
    )

    if (!resp.user) throw Error('No user')

    user.value = resp.user
  }

  return {
    email,
    password,
    login
  }
}
