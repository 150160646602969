import { createStore } from 'vuex'
import { db, user } from '../components/firebase.js'
export default createStore({
  state: {
    allRelationships: []
  },
  mutations: {
    setAllRelationships: function (state, payload) {
      state.allRelationships = payload
    }
  },
  actions: {
    loadData: function ({ commit }) {
      const tmp = []
      if (localStorage.allRelationships) {
        commit(
          'setAllRelationships',
          JSON.parse(localStorage.allRelationships)
        )
      }
      db.collection('users')
        .doc(user.value.uid)
        .collection('relationships')
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(doc => {
            const data = doc.data()
            tmp.push(Object.assign({ id: doc.id }, data))
          })
          commit('setAllRelationships', tmp)
          localStorage.allRelationships = JSON.stringify(tmp)
        })
    }
  },
  modules: {}
})
