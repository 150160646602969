<template>
  <div class="dropdown is-active">
    <div class="dropdown-trigger">
      <input
        class="input is-small"
        type="text"
        placeholder="Search"
        v-model="searchTerm"
        @focus="isActive=true"
        @blur="blur"

      />
    </div>

    <div
      class="dropdown-menu"
      id="dropdown-menu"
      role="menu"
      v-if="searchResults.length && isActive"
    >
      <div class="dropdown-content">
        <!-- <a href="#" class="dropdown-item"> Dropdown item </a>
        <a class="dropdown-item"> Other dropdown item </a>
        <a href="#" class="dropdown-item is-active"> Active dropdown item </a> -->
        <a
          :href="'/relationship/' + d.item.id"
          v-for="d in searchResults"
          :key="d.item.id"
          class="dropdown-item"
        >
          {{ d.item.fname + " " + d.item.lname }}
        </a>
        <hr class="dropdown-divider" />
        <a class="icon-text  dropdown-item" @click="addNew">
          <span class="icon">
            <i class="fas fa-user-plus"></i>
          </span>
          <span>Add New</span>
        </a>
        <router-link to="/import" class="dropdown-item">Import from Google</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Fuse from 'fuse.js'
import { db, user } from '../components/firebase.js'
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const isActive = ref(true)
    store.dispatch('loadData')
    let fuse
    const searchTerm = ref('')
    watchEffect(() => {
      fuse = new Fuse(store.state.allRelationships, {
        includeMatches: true,
        includeScore: true,
        keys: ['fname', 'lname', 'email']
      })
    })

    const searchResults = computed(() => {
      return fuse.search(searchTerm.value).slice(0, 10)
    })

    async function addNew () {
      const newDoc = await db
        .collection('users')
        .doc(user.value.uid)
        .collection('relationships')
        .add({})
      router.push(`/relationship/${newDoc.id}`)
    }
    function blur (event) {
      // for whatever reason closing the menu prevents the click to work with navigation
      setTimeout(() => {
        isActive.value = false
      }, 500)
    }
    return {
      searchTerm,
      searchResults,
      addNew,
      isActive,
      blur
    }
  }
}
</script>
