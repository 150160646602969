import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { user, initialised } from '@/components/firebase.js'
import { watch } from 'vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      public: true,
      footer: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/tend',
    name: 'Tend',
    component: () => import(/* webpackChunkName: "tend" */ '../views/Tend.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      public: true,
      footer: true
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      public: true,
      footer: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/relationship/:id?',
    name: 'Relationship',
    component: () => import(/* webpackChunkName: "relationship" */ '../views/Relationship.vue')
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "import" */ '../views/Import.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  if (initialised.value) {
    if (!to.matched.some(record => record.meta.public) && !user.value) {
      return next('/login')
    }

    next()
  } else {
    watch(
      () => initialised.value,
      (newVal) => {
        if (newVal) {
          if (!to.matched.some(record => record.meta.public) && !user.value) {
            return next('/login')
          }

          next()
        }
      }
    )
  }
})
export default router
